import React from "react";
import Slide from 'react-reveal/Slide';
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { light, dark } from 'assets/theme'

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

// import routes from "routes.js";

// import logo from "assets/img/react-logo.png";
// import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import styled from "styled-components";
import { Card, CardTitle, ModalHeader } from "reactstrap";
// import poemCSS from 'poems/style/poems.css'
// import "poems/style/reset.css";
import "poems/style/chippewa/dist/css/production.css";
import { Image } from "assets/components/Image";
import logo from "assets/img/react-logo.png";

const theme = light
theme.colors.primary = 'black'
const ThemeWrapper = styled.div`
height: 100vh;
width: 100vw;
color: ${props => props.theme.colors.secondary};
background-color: whitesmoke;
`

const PoemTitle = styled.h2`
    font-size: 2vw;
    margin: 0;
    padding: 1vw;
    text-align: left;
    color: darkslategrey !important;
    font-family: 'Georgia', serif;
    `;

const PoemText = styled.p`
    font-size: 2vw;
    margin: 0;
    padding-left: 1vw;
    text-align: ${props => props.textAlign};
    color: darkslategrey !important;
    font-family: 'Georgia', serif;
    font-weight: ${props => props.isTitle ? "bold" : "normal"};
    white-space: nowrap;
    line-height: 1.5;
    `;

const PoemSubtitle = styled.p`
    font-size: 1.3vw;
    margin: 0;
    padding:0;
    padding-left: 2vw;
    padding-top: 1vw;
    text-align: ${props => props.textAlign};
    color: darkslategrey !important;
    font-family: 'Georgia', serif;
    font-weight: 200;
    white-space: pre-line;
    font-style: italic;
    text-align: center;

    `;

const GalleryImage = styled.img`
    visibility: visible;
    border-radius: 0;
 
    `;

const StyledFooter = styled.div`
    bottom: 0;
    width: 100%;
    height: 5vh;
    margin-top: 5vh;
    padding-right: 8vw;
    p, a{
        text-align: right;
        color: lightslategrey !important;
        font-family: 'Georgia', serif;
        font-size: .8rem;
        
    }
    `;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8vw;
    padding-top: 0;
    scroll-snap-type: y mandatory;
    scroll-padding-top: 15vh;
    // overflow-y: scroll;
`
const PageContainer = styled.div`
`
const PoemRowContainer = styled.section`
    height: fit-content;
    scroll-snap-align: center !important; 
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 (-$column-padding);

    @media screen and (max-width: $grid-breakpoint) {
        margin: 0 auto;
    }
    align-items: center;
`

const BackButton = styled.a`
    padding-top: 5vh;
    padding-left: 8vw;
    padding-bottom: 0;
    background-color: transparent;
    border: none;
    color: slategrey;
    font-family: 'Georgia', serif;
    text-align: center;
    font-size: 1.5rem;
    cursor: pointer;
    :hover {
        color: black;
        
    }

`
const PoemImage = function (props) {
    return (
        <div className={"grid-column span-half pt3 pb3 mobile-m reveal-on-scroll is-revealing"}>
            <div className="relative">
                <GalleryImage class="info-image relative z2" src={props.image} draggable={false} />
            </div>
        </div>
    )
}
const PoemLines = function (props) {
    return (
        <div class={"grid-column span-half pt3 pb3 mobile-m"}>
            {props.text.map((row_idx, index) => {
                return <PoemText
                    adjustsFontSizeToFit={true}
                    numberOfLines={1}
                    id="poem-text"
                    textAlign={!props.isTitle? props.textAlign : "center"}
                    isTitle={props.isTitle}
                    >
                    {row_idx}

                </PoemText>
            })}
            {props.isTitle?<PoemSubtitle>&emsp;an illustrated poem</PoemSubtitle>:<></>}
        </div>)
}



const PoemRow = function (props) {
    return (
        <PoemRowContainer>
            {props.flipped ?
                (<>
                    <PoemLines text={props.text} textAlign="left" isTitle={props.isTitle} />
                    <PoemImage image={props.image} />
                </>) :
                (<>
                    <PoemImage image={props.image} />
                    <PoemLines text={props.text} textAlign="left" isTitle={props.isTitle} />
                </>)}

        </PoemRowContainer>
    )
}


var ps;
function PoemPage(props) {
    const poem_data = props.poem
    const title = poem_data.name
    const poem_rows = poem_data.rows

    return (
        <PageContainer theme={light}>
                <BackButton href="/poems">&larr;</BackButton>
                <Container theme={theme} >
                    {Object.keys(poem_rows).map((row_idx, index) => {
                        return index===0?
                        <>
                        <PoemRow 
                        image={
                            poem_rows[row_idx]["img"]}
                            text={poem_rows[row_idx]["text"]}
                                flipped={index % 2 === 0}
                                isTitle={true}>
                                </PoemRow>

                                    </>
                                :(
                            <PoemRow
                                image={poem_rows[row_idx]["img"]}
                                text={poem_rows[row_idx]["text"]}
                                flipped={index % 2 === 0}
                            />
                        )
                    })}
                    <BackButton href="/poems">&larr; back to other poems</BackButton>
                </Container>
            <StyledFooter><i><p>Some of these images were created by AI, for example: <a target="blank_" href="https://openai.com/dall-e-2/">DALL-E 2</a></p></i></StyledFooter>
        </PageContainer>
    );

}

export default PoemPage;
