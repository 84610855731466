import React from "react";
import styled from "styled-components";

// reactstrap components
import { Dropdown, DropdownToggle, Badge } from "reactstrap";
import { ThemeContext, themes } from "contexts/_ThemeContext";
import useTheme from 'hooks/useTheme'
import { backgroundColors } from "contexts/BackgroundColorContext";
import { SunIcon, MoonIcon, CogIcon } from "../../assets/svg";
import { Button } from "assets";

const ColorChangeButton  = styled(Button)`
margin-bottom:10px;
`
const ColorChangeMenu = styled.ul`
  height:fit-content;
  display:flex;
  flex-direction:column;
  min-height: 80px;
  background-color:${({theme})=> theme.colors.backgroundDisabled}!important;
`

const SettingsDropdown = styled(Dropdown)`
`

const MenuItem = styled.li`
`

const DropdownToggleStyled = styled(DropdownToggle)`
`

const FPDiv = styled.div`
  background-color:${({theme})=> theme.colors.card}!important;
`

function FixedPlugin(props) {
  const { isDark, toggleTheme, theme } = useTheme()
  const [dropDownIsOpen, setdropDownIsOpen] = React.useState(false);
  const handleClick = () => {
    setdropDownIsOpen(!dropDownIsOpen);
  };
  return (
    <FPDiv className="fixed-plugin">
      <SettingsDropdown isOpen={dropDownIsOpen} toggle={handleClick}>
        <DropdownToggleStyled tag="div">
          <CogIcon width="32px" color={theme.colors.contrast}/>
        </DropdownToggleStyled>
        <ColorChangeMenu className="dropdown-menu show">
                   <MenuItem className="adjustments-line text-center color-change">
                   <ColorChangeButton variant={isDark? "primary": "subtle"} onClick={()=>{toggleTheme()}}>
                    <SunIcon />
                   </ColorChangeButton>
                   </MenuItem> 
                   <MenuItem className="adjustments-line text-center color-change">
                   <ColorChangeButton variant={isDark?"subtle" : "primary"} onClick={()=>{toggleTheme()}}> 
                    <MoonIcon/>
                   </ColorChangeButton>
                   </MenuItem> 
                   {" "}
        </ColorChangeMenu>
      </SettingsDropdown>
    </FPDiv>
  );
}

export default FixedPlugin;
