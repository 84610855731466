import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { AlertTheme } from "../components/Alert/types";
import { ButtonTheme } from "../components/Button/types";
import { CardTheme } from "../components/Card/types";
import { RadioTheme } from "../components/Radio/types";
import { ToggleTheme } from "../components/Toggle/types";
import { NavTheme } from "../widgets/Menu/types";
import { ModalTheme } from "../widgets/Modal/types";
import { Colors, Breakpoints, MediaQueries, Spacing, Shadows, Radii, ZIndices } from "./types";

export interface GlobalTheme {
    siteWidth: number;
    isDark: boolean;
    alert: AlertTheme;
    colors: Colors;
    button: ButtonTheme;
    card: CardTheme;
    nav: NavTheme;
    modal: ModalTheme;
    radio: RadioTheme;
    toggle: ToggleTheme;
    breakpoints: Breakpoints;
    mediaQueries: MediaQueries;
    spacing: Spacing;
    shadows: Shadows;
    radii: Radii;
    zIndices: ZIndices;
}

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends GlobalTheme {}
}
const darkGradient = "#1d1f2e, #20213f"
// const darkGradient = "#efefcf, #efcfef"

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Poppins', sans-serif;
  }
  body {
    background: linear-gradient(
      10deg,
      ${({ theme }) => theme.isDark? darkGradient:`rgb(244, 255, 254), ${theme.colors.background}`}
      );
    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
