import React, { useEffect, Suspense, lazy } from "react";
import PageLoader from "components/PageLoader";

import { Route, Switch, Redirect, useLocation, Link } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { light, dark } from 'assets/theme'

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

// import routes from "routes.js";

// import logo from "assets/img/react-logo.png";
// import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import styled from "styled-components";
import { Card, CardTitle, ModalHeader } from "reactstrap";
// import poemCSS from 'poems/style/poems.css'
// import "poems/style/reset.css";
import "poems/style/chippewa/dist/css/production.css";
import { Image } from "assets/components/Image";
import logo from "assets/img/react-logo.png";
import PoemPage from "./PoemPage";


const NotFound = lazy(() => import("views/NotFound"));

const theme = light
theme.colors.primary = 'black'
const ThemeWrapper = styled.div`
height: fit-content;
width: 100%;
color: ${props => props.theme.colors.secondary};
background-color: whitesmoke;
`

var ps;

const PoemsList = styled.ul`

    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    font-size: 1.5vw;
    font-family: 'Georgia', serif;
    `;

const VerticalBar = styled.ul`
    border-left: 3px solid darkslategrey;
    height: 80%;
    position: absolute;
    left: 30%;
    top: 10%;
    padding: 0;
    margin: 0%;
    `;



const PoemsTitle = styled.p`
    font-size: 2vw;
    margin: 0;
    padding: 1vw;
    text-align: right;
    color: darkslategrey !important;
    font-family: 'Georgia', serif;
    font-weight: 600;
    :hover {
        transition: transform 0.2s; /* Animation */
        transform: scale(1.1);
        text-decoration: underline;
        pointer: cursor;
    }
    `;


    const PageContainer = styled.div`
    height: 100vh;
    width: 100vw;
    `;

function Poems(props) {
    console.log(theme)
    const location = useLocation();
    const poem_name = location.pathname.split("/")[2]


    const loaded_json = require(`poems/poems.json`)
    const poem_in_data = poem_name in loaded_json
    console.log(loaded_json)
    if (poem_in_data) {
        const poem = loaded_json[poem_name]
        return (<ThemeWrapper>
            <PoemPage poem={poem} />

        </ThemeWrapper>)

    }

    // else menu
    return (

        <ThemeWrapper >
            <React.Fragment theme={light}>

                <PageContainer theme={light}>

                    <main>
                        <div theme={theme} className="container">
                            <PoemsList>
                                {Object.keys(loaded_json).map((poem_name, index) => {
                                    return (
                                        <li key={index}>
                                            <a href={loaded_json[poem_name]["url"]}><PoemsTitle>{loaded_json[poem_name]["name"]}</PoemsTitle></a>
                                        </li>
                                    )
                                })}
                            </PoemsList>

                        </div>
                    </main>

                </PageContainer>
            </React.Fragment>
            <VerticalBar/>
        </ThemeWrapper>
    );

}

export default Poems;
