import React, { useState } from "react";
import { useTheme } from "styled-components";
import Svg from "assets/svg/Svg";
import { SvgProps } from "assets/svg/types";;

const Icon: React.FC<SvgProps> = (props) => {
  const theme = useTheme();
  const primaryColor = theme.isDark ? "#4b698a" : "#2d4053";
  const secondaryColor = theme.isDark ? "#526a80" : "#425b72";

  return (
    <Svg viewBox="0 0 48 48" {...props}>
      <g id="User_profile" data-name="User profile">
    <path fill={primaryColor} d="M47,24A23,23,0,1,1,12.81,3.91,23,23,0,0,1,47,24Z" />
    <path fill={secondaryColor} d="M47,24a22.91,22.91,0,0,1-8.81,18.09A22.88,22.88,0,0,1,27,45C5.28,45-4.37,17.34,12.81,3.91A23,23,0,0,1,47,24Z" />
    <path fill="#6fabe6" d="M39.2,35.39a19,19,0,0,1-30.4,0,17,17,0,0,1,10.49-8.73,8.93,8.93,0,0,0,9.42,0A17,17,0,0,1,39.2,35.39Z" />
    <path fill="#82bcf4" d="M39.2,35.39a19,19,0,0,1-4.77,4.49,19,19,0,0,1-15.13-1,7.08,7.08,0,0,1-.51-12.18c.1-.07,0-.05.5-.05a9,9,0,0,0,9.42,0A17,17,0,0,1,39.2,35.39Z" />
    <path fill="#6fabe6" d="M33,19a9,9,0,1,1-12.38-8.34A9,9,0,0,1,33,19Z" />
    <path fill="#82bcf4" d="M33,19a9,9,0,0,1-2.6,6.33c-9.13,3.74-16.59-7.86-9.78-14.67A9,9,0,0,1,33,19Z" />
  </g>
    </Svg>
  );
};

export default Icon;
