// /* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { UserConfig} from 'config/constants/types'

// import fetchFarms from './fetchFarms'
// import {
//   fetchUserAllowances,
//   fetchUserTokenBalances,
// } from './fetchUser'
import { UserState, User } from '../types'

const initialState: UserState = { data:[]}

export const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      
    },
  },
}) as any;



// // Actions
// export const { setFarmsPublicData, setFarmUserData } = farmsSlice.actions

// // Thunks
// export const fetchFarmsPublicDataAsync = () => async (dispatch) => {
//   const farms = await fetchFarms()
//   dispatch(setFarmsPublicData(farms))
// }
// export const fetchFarmUserDataAsync = (account) => async (dispatch) => {
//   const userFarmAllowances = await fetchFarmUserAllowances(account)
//   const userFarmTokenBalances = await fetchFarmUserTokenBalances(account)
//   const userStakedBalances = await fetchFarmUserStakedBalances(account)
//   const userFarmEarnings = await fetchFarmUserEarnings(account)

//   const arrayOfUserDataObjects = userFarmAllowances.map((farmAllowance, index) => {
//     return {
//       index,
//       allowance: userFarmAllowances[index],
//       tokenBalance: userFarmTokenBalances[index],
//       stakedBalance: userStakedBalances[index],
//       earnings: userFarmEarnings[index],
//     }
//   })

//   dispatch(setFarmUserData({ arrayOfUserDataObjects }))
// }

export default userSlice.reducer
