import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";

import logo from "assets/img/react-logo.png";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import styled from "styled-components";
import { Card, CardTitle, ModalHeader } from "reactstrap";
import { Carousel } from "react-carousel-minimal";

var ps;

const Gallery = styled.div`
  margin: auto;
  padding: 10px;
  text-align: center;
  display: inline-block;
  flex-direction: column;
  max-width: 100%;
  max-height: 100vh;
  height: 80vh;
  overflow: scroll;
  scrollbar-width: none;
  overflow-y: auto;
`;

const PageWrapper = styled.div`
  max-height: 100%;
  min-height: 100vh;
  overflow: hidden;
`;

const GalleryImage = styled.img`
  align-items: center;
  align-self: center;
  align-content: center;
  padding: 5px;
  margin: auto;
  justify-content: space-around;
  box-shadow: 1px 1px 3px black;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  :hover {
    transition: transform 0.2s; /* Animation */
    width: 50vh;
    color:blue;
  }
`;

const Title = styled.h1`
  text-align: left;
  padding-top: 2%;
  padding-left: 3%;
  margin: auto;
  font-size: 6vh;
  font-weight: bold;
  `;
const SubTitle = styled.a`
    padding-left: 3%;
    color: inherit;
    text-decoration: none;
`
const TitleCard = styled.div`
    height: 20vh;
    background-color: #00000066;
    box-shadow: 1px 1px 3px black;
    margin-bottom: 0%;
    vertical-align:middle;

`

function WalkerPage(props) {
  const location = useLocation();
  const mainPanelRef = React.useRef(null);
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current, {
        suppressScrollX: true,
      });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);

  const cache = {};

  function importAll(r) {
    r.keys().forEach((key) => (cache[key] = r(key)));
  }
  importAll(
    require.context("../../assets/img/walker", false, /\.(png|jpe?g|svg)$/)
  );

  const images = Object.entries(cache).map((module) => module[1].default);

  return (
    <PageWrapper>
      <BackgroundColorContext.Consumer>
        {({ color, changeColor }) => (
          <React.Fragment>
            <div className="wrapper">
              <div ref={mainPanelRef} data={color}>
              <TitleCard>
                <Title>A Random Walk</Title>
                <SubTitle href="https://opensea.io/collection/a-random-walk" target="_blank"><i>available on opensea</i></SubTitle>
              </TitleCard>
                <Gallery>
                  {images.map((image) => (<a href="https://opensea.io/collection/a-random-walk" target="_blank">

                    <GalleryImage style={{ width: "40vh" }} src={image} draggable={false}/>
                  </a>
                  ))}
                </Gallery>
                {/* <p>Testing 12345</p> */}
              </div>
            </div>
          </React.Fragment>
        )}
      </BackgroundColorContext.Consumer>
    </PageWrapper>
  );
}

export default WalkerPage;
