import React, { useEffect, Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,
} from "react-router-dom";
import PageLoader from "components/PageLoader";
import { ToastListener } from './contexts/ToastsContext'
import HomePage from "layouts/HomePage/HomePage.js";
import Poems from "poems/Poems.jsx";
import WalkerPage from "layouts/WalkerPage/WalkerPage.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from 'assets'
import ResetCSS from 'assets/ResetCSS'

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import GlobalStyle from "assets/theme/Global";
import Providers from "Providers";
const NotFound = lazy(() => import("views/NotFound"));

ReactDOM.render(
  <Providers>
  <BrowserRouter >
    {/* <ResetCSS /> */}
    <GlobalStyle />
    {/* <BackgroundColorWrapper> */}
      {/* <BrowserRouter> */}
      {/* <Switch> */}
      {/* <Route path="/" render={(props) => <HomePage {...props} />} /> */}
      {/* </Switch> */}
      {/* </BrowserRouter> */}
    {/* </BackgroundColorWrapper> */}

    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path="/poems">
          <Poems />
        </Route>
        <Route path="/walk">
          <WalkerPage/>
        </Route>
        <Route component={NotFound} />
      </Switch>
    </Suspense>
    <ToastListener />
  </BrowserRouter >
  </Providers>,
  document.getElementById("root")
);
