import React from 'react'
import { ModalProvider } from './assets/widgets/Modal'
// import bsc, { UseWalletProvider } from '@binance-chain/bsc-use-wallet'
// import * as bsc from '@binance-chain/bsc-use-wallet'
import { Provider } from 'react-redux'
import getRpcUrl from 'util/getRpcUrl'
import { LanguageContextProvider } from 'contexts/Localisation/languageContext'
import { ThemeContextProvider } from 'contexts/ThemeContext'
import { WalletContextProvider } from 'contexts/WalletProvider'
import { BlockContextProvider } from 'contexts/BlockContext'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { ToastsProvider } from 'contexts/ToastsContext'
import store from 'state'

const Providers: React.FC = ({ children }) => {
  const rpcUrl = getRpcUrl()
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID);
  return (
    <Provider store={store}>
      <ThemeContextProvider>
      <ToastsProvider>
        {/* <LanguageContextProvider> */}
          {/* <WalletContextProvider
            chainId={chainId}
            connectors={{
              walletconnect: { rpcUrl },
              bsc,
            }}
          > */}
            <BlockContextProvider>
              <RefreshContextProvider>
                <ModalProvider>{children}</ModalProvider>
              </RefreshContextProvider>
            </BlockContextProvider>
          {/* </WalletContextProvider> */}
        {/* </LanguageContextProvider> */}
      </ToastsProvider>
      </ThemeContextProvider>
    </Provider>
  )
}

export default Providers
